<template>
  <div id="cms-menu-show">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Menu - Show</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Name:"
                    label-for="name"
            >
              <b-form-input id="name" v-model="menu.name" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Description (English):"
                    label-for="remark_en"
            >
              <b-form-input id="remark_en" v-model="menu.remark_en" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Description (Dutch):"
                    label-for="remark_nl"
            >
              <b-form-input id="remark_nl" v-model="menu.remark_nl" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Image (http link):"
                    label-for="image"
            >
              <b-form-input id="image" v-model="menu.image" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Price:"
                    label-for="price"
            >
              <b-form-input id="price" v-model="menu.price" disabled></b-form-input>
            </b-form-group>
            <router-link class="link-color" :to="{ name: 'CmsMenu' }" @click.prevent>
              <b-button type="reset" variant="dark">Back</b-button>
            </router-link>
            <router-link class="link-color" :to="{ name: 'CmsMenuUpdate', params: { id: `${this.$route.params.id}` }}" @click.prevent>
              <b-button type="reset" variant="warning">Edit</b-button>
            </router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu.vue'

export default {
  data() {
    return {
      loading: true,
      menu: [],
    }
  },
  components: {
    SideMenu
  },
  beforeCreate () {
    this.axios
        .get(`https://batavia-backend.herokuapp.com/api/menu/${this.$route.params.id}`)
        .then(response => (
            this.menu = response.data,
                this.menu['price'] = this.menu['price'].toFixed(2)
        ))
        .finally(() => {
          this.loading = false
        })
  }
}
</script>